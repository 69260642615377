import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from '../store'
import { Notification} from 'element-ui'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../views/index.vue'),
    redirect: '/message',
    children: [
      {
        path: '/message',
        meta: {
          title: 'AI问答',
          menuActive: 'message',
          checkToken: true
        },
        component: () => import('../views/message/message.vue')
      },
      {
        path: '/draw',
        meta: {
          title: 'AI绘图',
          menuActive: 'draw',
          checkToken: true
        },
        component: () => import('../views/draw/draw.vue')
      },
      {
        path: '/template',
        meta: {
          title: '会话模板',
          menuActive: 'template',
          checkToken: true
        },
        component: () => import('../views/template/template.vue')
      },
      {
        path: '/feedBack',
        meta: {
          title: '意见反馈',
          menuActive: 'feedBack',
          checkToken: true
        },
        component: () => import('../views/feedBack/feedBack.vue')
      },
      {
        path: '/theme',
        meta: {
          title: '主题应用',
          menuActive: 'theme',
          checkToken: true
        },
        component: () => import('../views/theme/theme.vue')
      },
      {
        path: '/AISchool',
        meta: {
          title: 'AI学院',
          menuActive: 'AISchool',
          checkToken: true
        },
        component: () => import('../views/AISchool/AISchool.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/userActions/login.vue')
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/shareFriends/shareFriends.vue')
  }
]
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let TOKEN = Vuex.state?.userStoreEmploye?.userInfo?.token ?? null
  if (to.meta.checkToken && !TOKEN) {
    Notification({
      title: '提示',
      type: 'warning',
      message: '请登录后访问'
    })
    next('/login')
  } else {
    next()
  }
})

export default router
