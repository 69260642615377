import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import userStoreEmploye from './userStoreEmploye'
import messageStore from './messageStore'
import mjImgStore from './mjImgStore'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    collapse: false // 左侧菜单展开/收缩状态
  },
  getters: {
  },
  mutations: {
    handleCollapse(state) {
      state.collapse = !state.collapse
    }
  },
  actions: {
    handleCollapse(content) {
			content.commit('handleCollapse')
		}
  },
  modules: {
    userStoreEmploye,
    messageStore,
    mjImgStore
  },
  plugins: [
    // 持久化存储
    createPersistedstate(
      {
        key: 'VuexStorageEmployee',
        path: ['userStoreEmploye']
        // storage: 'window.sessionStorage',  默认为localstorage
        // reducer(val) {
        //   return {
        //     collapse: val.collapse,
        //     userInfo: val.UserStore
        //   }
        // }
      })
  ]
})
