import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 请求接口api挂载到window上
import Api from './api/api'
window.API = Api
Vue.prototype.$API = Api

Vue.config.productionTip = false

import * as filters from './utils/filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]) // 插入过滤器名和对应方法
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
