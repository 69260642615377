import Router from '@/router/index'
import { Notification } from 'element-ui'
import API from '@/api/api'
const userStoreEmploye = {
    state: {
        userInfo: {}
    },
    getters: {},
    mutations: {
        saveUserInfo(state, data) {
            state.userInfo = data
        },
        removeUserInfo(state) {
            state.userInfo = {}
        }
    },
    actions: {
        async login(context, params) {
            let res = await API.login(params)
            if (res?.code === 0) {
                Notification({
                    title: '登录成功',
                    type: 'success',
                    message: `欢迎回来，${res.data.truename}`
                })
                context.commit('saveUserInfo', res.data)
                Router.push({
                    path: '/'
                })
                window.localStorage.setItem('tokenExpireTime', new Date().getTime() + 24 * 60 * 60 * 1000)
            }
        },
        logout(context){
            context.commit('saveUserInfo')
            Router.push({
                path: '/login'
            })
            window.localStorage.removeItem('tokenExpireTime')
            window.localStorage.removeItem('VuexStorageEmployee')
        }
    }
}
export default userStoreEmploye