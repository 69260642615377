const messageStore = {
    state: {
        sendMessageInfo: {},
        printState: false
    },
    getters: {},
    mutations: {
        setSendMessageInfo(state, data) {
            state.sendMessageInfo = data
        },
        changePrintState(state, data) {
            state.printState = data
        }
    },
    actions: {}
}
export default messageStore