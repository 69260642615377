import axios from '../utils/request/request'
const theme = {
    // 主题应用列表
    themeList: params => axios.post('/frontend/chat/theme', params),
    // 写作列表
    writeList: params => axios.post('/frontend/write', params),
    // 写作风格
    writeStyle: params => axios.post('/frontend/chat/style', params),
    // 提交写作
    writeSubmit: params => axios.post('/frontend/chat/write', params),
    // 写作详情
    writeDetail: params => axios.post('/frontend/write/detail', params)
}
export default theme