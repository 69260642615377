import axios from '../utils/request/request'
const aiSchool = {
    // 视频列表
    videoList: params => axios.post('/frontend/course/videolist', params),
    // 视频详情
    videoDetail: params => axios.post('/frontend/course/videodetail', params),
    // 视频浏览
    videoPv: params => axios.post('/frontend/course/videopv', params),
     // 图文列表
     articleList: params => axios.post('/frontend/course/articlelist', params),
     // 图文详情
     articleDetail: params => axios.post('/frontend/course/articledetail', params),
     // 图文浏览
     articlePv: params => axios.post('/frontend/course/articlepv', params)
}
export default aiSchool