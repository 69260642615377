import userActions from './userActions'
import message from './message'
import template from './template'
import feedBack from './feedBack'
import theme from './theme'
import aiSchool from './aiSchool'
import draw from './draw'
const api = {
    // 用户登录等接口
    ...userActions,
    // 消息接口
    ...message,
    // 绘画
    ...draw,
    // 会话模板
    ...template,
    // 意见反馈
    ...feedBack,
    // 应用主题
    ...theme,
    // ai学院
    ...aiSchool
}
export default api