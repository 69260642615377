const mjImgStore = {
    state: {
        mjImgInfo: {},
        loadingImg: false
    },
    getters: {},
    mutations: {
        setMJImgStoreInfo(state, data) {
            state.mjImgInfo = data
        },
        changeLoadingImgState(state, data) {
            state.loadingImg = data
        }
    }
}
export default mjImgStore