import axios from '../utils/request/request'
const message = {
    // 历史会话
    chatHistory: params => axios.post('/frontend/chat/history', params),
    // 随机模板列表
    chatTemplateSJ: params => axios.post('/frontend/chat/template', params),
    // 会话详情
    chatDetail: params => axios.post('/frontend/chat/detail', params),
    // 同事问答
    chatSharelist: params => axios.post('/frontend/chat/sharelist', params),
    // 会话显示隐藏
    chatChangeStatus: params => axios.post('/frontend/chat/changeStatus', params),
    // 模型列表
    chatFormerList: params => axios.post('/frontend/chat/formerlist', params),
    // 会话提问
    chatAsk: params => axios.post('/frontend/chat/askapi', params),
    // 同事列表
    chatUserList: params => axios.post('/frontend/user', params),
    // 会话统计
    chatStatics: params => axios.post('/frontend/index/', params),
    // 分享
    chatShare: params => axios.post('/frontend/chat/share', params),
    // 朋友分享
    ShareChat: params => axios.post('/frontend/share/chat', params),
    // 发送消息
    streamSendMsg: params => axios.post('/frontend/chat/setstream', params),
    // 接受数据流
    getStreamErrorMsg: params => axios.post('/frontend/chat/errorMsg', params)
}
export default message