import axios from '../utils/request/request'
const draw = {
    // 绘画列表
    drawList: params => axios.post('/frontend/mj/list', params),
    // 绘画详情
    drawDetail: params => axios.post('/frontend/mj/detail', params),
    // 绘画提交
    drawSend: params => axios.post('/frontend/mj/mjapi', params),
    // 
    mjbutton: params => axios.post('/frontend/mj/mjbutton', params),
    // 下载
    downloadMJImg: params => axios.post('/frontend/mj/download', params),
    mjcheck: params => axios.post('/frontend/mj/mjcheck', params)
}
export default draw