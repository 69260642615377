import axios from 'axios'
import { Notification} from 'element-ui'
import router from '@/router/index'
import vuex from '@/store/index'
const Http = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data;'
  },
  timeout: 300 * 1000
})
// 请求拦截
Http.interceptors.request.use(
    config => {
        if (config.url === '/frontend/share/chat') {
            return config
        }
        let TOKEN = vuex.state.userStoreEmploye?.userInfo?.token ?? null
        let tokenExpireTime = window.localStorage.getItem('tokenExpireTime')
        // token存在并且在过期时间内
        if (TOKEN && tokenExpireTime > new Date().getTime()) {
            config.headers.token = TOKEN
            // 增加token的延迟时间 1小时
            window.localStorage.setItem('tokenExpireTime', new Date().getTime() + 24 * 60 * 60 * 1000)
        } else {
            let location = window.location.href
            if (location.indexOf('login') < 0) {
                router.push({
                    path: '/login'
                })
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// 响应拦截
Http.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data
        } else {
            Notification({
                type: 'error',
                title: '请求失败',
                message: response?.msg ?? ''
            })
            return Promise.reject('error')
        }
    }
)
// 重新整理url，将id拼到url后面
// function rebuildUrl(url, params) {
//     if (params.hasOwnProperty('id')) {
//         url = url + `/${params.id}`
//     }
//     return url
// }
function rebuildRequest(method, url, params = {}, options = {}) {
    return new Promise((reslove, reject) => {
        if (method == 'get') {
            options.params = params
        } else {
            options.data = params
        }
        const config = Object.assign({
            url,
            method,
            ...options
        })
        Http.request(config).then(res => {
            if (res.code === 0) {
                reslove(res)
            } else if (res.code === -1) {
                Notification({
                    type: 'info',
                    title: '登录状态异常',
                    message: res.msg
                })
                router.push({
                    path: '/login'
                })
            } else {
                Notification({
                    type: 'info',
                    title: '请求出错',
                    message: res.msg
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}
const server = {
    get: rebuildRequest.bind(null, 'get'),
    post: rebuildRequest.bind(null, 'post'),
    del: rebuildRequest.bind(null, 'delete'),
    put: rebuildRequest.bind(null, 'put'),
    Http
}
export default server