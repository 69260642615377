const Time = {
    /** 
     * 时间戳格式化函数 
     * @param  {string} format    格式 
     * @param  {int}    timestamp 要格式化的时间 默认为当前时间 
     * @return {string}           格式化的时间字符串 
     */
    formatTime(format, timestamp) {
        if (!timestamp) return ''
        let date = ''
        let length = timestamp.toString().length
        if (length < 13) {
            date = new Date(timestamp * 1000)
        } else {
            date = new Date(timestamp)
        } // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear(),
        M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
        D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()),
        h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()),
        m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()),
        s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
        if (format == 'y-m-d') {
            return Y + '-' + M + '-' + D
        } else {
            return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
        }
        
    }
}
export default Time